import io from 'socket.io-client';

let socket;
let auth = {};

export const setupSocket = (url, cb) => {
    socket = io(url);
    socket.on('connect', (s) => {
        console.log('Connected!', socket);
        cb()
    });
}

export const socketOn = (msg, cb) => {
    socket.on(msg, cb);
}

export const socketEmit = (msg, data, cb) => {
    socket.emit(msg, data, res => {
        cb && cb(res);
    })
}

export const enterGame = (data, cb) => {
    socket.emit('enter', data, (gameData) => {
        auth = data;
        cb(gameData);
    })
}

export const saveResult = (resData, cb) => {
    const payload = {...auth, ...{ result: resData }};
    socket.emit('result', payload, (result) => {
        cb(result);
    })
}
