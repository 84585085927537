import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Utils
import { validateEmail } from '../../helpers/utils';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
import List from './list';
import AddPlayer from './add_player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      item: this.props.item || null,
      pair_modal: false,
      new_players: [],
      scores: [],
      loading_scores: true,
    };

    // Bindings
    this.addPairModal = this.addPairModal.bind(this);
    this.handleNewPlayers = this.handleNewPlayers.bind(this);
    this.submitNewPlayers = this.submitNewPlayers.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
      fetch(`/api/games/${this.props.match.params.id}/scores`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ scores: data, loading_scores: false });
        });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  addPairModal() {
    this.setState({ pair_modal: !this.state.pair_modal });
  }

  handleNewPlayers(newPlayers) {
    this.setState({ new_players: newPlayers });
  }

  submitNewPlayers() {
    const { game } = this.props;
    this.props.dispatch(
      gameActions.addPlayers(game.data._id, this.state.new_players)
    );
    this.addPairModal();
    this.setState({ new_players: [] });
  }

  render() {
    const { copied, loading_scores } = this.state;
    const { game } = this.props;

    let players = game.data.players || [];

    players = players.map((p, pi) => {
      p.index = pi + 1;
      const ml =
        process.env.REACT_APP_GAME_URL +
        '?i=' +
        p.game_id +
        '&e=' +
        p.email +
        '&t=' +
        p.token;
      p.count = loading_scores
        ? '...'
        : this.state.scores.filter((s) => s.email === p.email).length;
      p.magiclink = (
        <a href={ml} target='_blank' rel='noopener noreferrer'>
          <code style={{ maxWidth: '100px' }}>{ml}</code>
        </a>
      );
      return p;
    });

    // Validate that all the players have a valid email
    const validNewPlayers =
      this.state.new_players.length === 0
        ? false
        : this.state.new_players.every((pl) => {
            return validateEmail(pl.email);
          });
    // Validate if there are repeated players
    const repeatedPlayers = this.state.new_players.filter((pl) =>
      players.find((p) => p.email === pl.email)
    );

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Player Management
          {/* <small style={{ fontSize: '.8rem' }}>({ players.length } players)</small> */}
          <span
            style={{
              fontSize: '.7rem',
              lineHeight: '1rem',
              padding: '8px 10px 8px 10px',
              verticalAlign: 'middle',
            }}
            className='badge badge-success badge-pill ml-3'>
            {players.length} players
          </span>
          {game.data && (
            <small className='ml-3 mr-auto'>
              <span
                className='badge badge-pill badge-dark'
                style={{
                  fontSize: '1rem',
                  padding: '5px 0px 0px 0px',
                  verticalAlign: 'middle',
                }}>
                <span
                  style={{ marginTop: '-5px', padding: '10px 10px' }}
                  className='badge badge-secondary badge-pill'>
                  Sign-up
                </span>
                <code className='text-white ml-2 mr-2'>
                  {game.data.short_url}
                </code>
                <CopyToClipboard
                  text={game.data.short_url}
                  onCopy={() => this.setState({ copied: true })}>
                  <span
                    style={{
                      marginTop: '-5px',
                      padding: '10px 10px',
                      cursor: 'pointer',
                    }}
                    className='badge badge-primary badge-pill'>
                    {copied ? 'Copied' : 'Copy link'}
                  </span>
                </CopyToClipboard>
              </span>
            </small>
          )}
          {/* <div className="d-inline-block ml-3">
                    <div className="input-group input-group-sm mb-3">
                        <input type="text" className="form-control" style={{ width: 'auto' }} value={game.data.short_url} />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2">Copy</button>
                        </div>
                    </div>
                </div> */}
          <small className='float-right'>
            <button
              className='btn btn-outline-primary'
              onClick={this.addPairModal}>
              Add Player
            </button>
            <button
              className='btn btn-outline-primary ml-3'
              onClick={(e) => window.location.reload()}>
              <FontAwesomeIcon icon='redo-alt' />
            </button>
          </small>
        </h2>
        <List
          labels={[
            '#',
            'Name',
            'Surname',
            'Email',
            <span style={{ whiteSpace: 'nowrap' }}>Times Played</span>,
            'MagicLink',
          ]}
          fields={['index', 'name', 'lastname', 'email', 'count', 'magiclink']}
          sortables={['index', 'name', 'lastname', 'email', 'count']}
          data={players}
          //   size='table-sm'
        />

        <Modal
          isOpen={this.state.pair_modal}
          toggle={this.addPairModal}
          centered={true}
          size={'lg'}>
          <ModalHeader>Add Player</ModalHeader>
          <ModalBody className='text-center'>
            <p>
              Enter the player information and click the <b>"Add"</b> button.
            </p>
            <AddPlayer onChange={this.handleNewPlayers} />
            {repeatedPlayers.length > 0 && (
              <div className='alert alert-danger mt-4'>
                The following players are already in this session:{' '}
                <b>{repeatedPlayers.map((p) => p.email).join(', ')}</b>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.addPairModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.submitNewPlayers}
              disabled={!validNewPlayers || repeatedPlayers.length > 0}>
              Add
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedPlayersPage = connect(mapStateToProps)(Players);
export { connectedPlayersPage as GamePlayers };
