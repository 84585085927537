import React from 'react'
import { Link, Route } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { gamesActions, gameActions } from '../../actions';


import { GameForm } from './form';
import { GamePlayers } from './players';
import { GameDebrief } from './debrief';
import { GameOffers } from './offers';
import { GameTypes } from './types';
import { GamePareto } from './pareto';
import { GameLeaderboard } from './leaderboard';


class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.item || null
        }

        // Bindings

    }

    componentDidMount(){
        const { match } = this.props;
        const { id } = match.params;
        this.props.dispatch( gameActions.fetchGames(id) )
    }

    render(){

        const { game, match } = this.props;
        // const { item } = this.state;

        return <div>
            { game && game.data && <div>
                <Route exact path={match.path} component={GameForm} />
                <Route exact path={match.path + '/players'} component={GamePlayers} />
                <Route exact path={match.path + '/debrief/overview'} component={GameDebrief} />
                {/* <Route exact path={match.path + '/debrief/offers'} component={GameOffers} />
                <Route exact path={match.path + '/debrief/types'} component={GameTypes} />
                <Route exact path={match.path + '/debrief/pareto'} component={GamePareto} /> */}
                <Route exact path={match.path + '/debrief/leaderboard'} component={GameLeaderboard} />
            </div>}
        </div>
        
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

const connectedGamesPage = connect(mapStateToProps)(Form);
export { connectedGamesPage as GameItem };