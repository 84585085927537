import React from 'react'
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

import GroupBtns from '../../components/GroupBtns';

// Setting Highcharts
Highcharts.setOptions({
    lang: {
        thousandsSep: ','
    }
});

class Pareto extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            order: 0,
            clicked: null
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this);
        this.tagOffers = this.tagOffers.bind(this)

    }

    componentDidMount(){
        if(this.props.match.params.id){
            this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))
        }
    }

    handleChange(e){
        const n  = e.target.name;
        const v  = e.target.value;
        let data = {}
        data[n]  = v;
        this.setState( data );
    }

    totalPoints( o ){
        let total = [0, 0];
        if( o.data ){
            for( let i = 0; i < o.data.length; i++ ){
                const issue = o.data[i];
                const selected = issue.values.find( v => v.selected )
                if( selected ){
                    total[0] += selected.points[0]
                    total[1] += selected.points[1]
                }
                if( i === o.data.length-1 )
                    return total;
            }
        }
    }

    setDefault( o ){
        let offer = JSON.parse(JSON.stringify(o))
        offer.data = offer.data.map( issue => {
            // return {...issue, ...{ values: issue.values.map( v => v ) } }
            return {...issue, ...{ values: [...issue.values.map( v => v )] } }
        })
        offer.order = 0
        offer.data = offer.data.map( (issue, ii) => {
            issue.values = issue.values.map( (v, vi) => {
                if( vi === 0 ) v.selected = true
                else v.selected = false
                return v
            })
            return issue
        });
        return offer
    }

    getIssuesDiff( of1, of2 ){
        // console.log(of1.data, of2.data);
        const data1 = of1.data.map( issue => {
            return {...issue, ...{ values: issue.values.map( v => v ) } }
        })
        const data2 = of2.data.map( issue => {
            return {...issue, ...{ values: issue.values.map( v => v ) } }
        })

        const issues = data1.filter( (d, di) => {
            const selected1 = d.values.find( v => v.selected );
            const selected2 = data2[di].values.find( v => v.selected );
            if( selected1 && selected2 )
                return selected1.label !== selected2.label;
        });
        return issues;
    }

    getMesos( offers ){
        return offers.filter( (it, i) => {
            const prev = i > 0 ? offers[i-1] : null;
            return prev && prev.order === it.order;
        })
    }

    tagOffers( offers ){
        const rawOffers = JSON.parse(JSON.stringify(offers))
        return rawOffers.map((it, i) => {

            const prev = i > 0 ? rawOffers[i-1] : this.setDefault(rawOffers[0]);
            let diff = null;
            diff = this.getIssuesDiff( prev, it)
            if( diff.length === 1 ) it.type = 'ibi';
            else it.type = 'pack'

            // Is this a MESOs offer?
            if( prev.order === it.order ){
                prev.type = 'meso'
                it.type = 'meso'
            }

            return it;
        })
    }

    groupsModal( e ){
        this.setState({
            clicked: [e.point.x, e.point.y]
        })
    }

    render(){

        const self = this;
        const { game } = this.props;
        let lastOfferPerGroup = [];
        if( game.data.groups && this.state.order > -1 ){

            // Tag IssueByIssue
            lastOfferPerGroup = game.data.groups.map( p => {

                let last = null;
                if( p.items.length === 0 )
                    return last;

                    // Last one?? Last one accepted ??
                    last = p.items.find( o => o.status === 'accepted' ) //p.items[ p.items.length - 1 ]
                    if( !last )
                        return last;

                    // Cheap clone
                    last = JSON.parse(JSON.stringify(last))

                    const taggedOffers = this.tagOffers(p.items);
                    // console.log({ taggedOffers })

                    // Used MESOS?
                    if( taggedOffers.find( o => o.type === 'meso') ){
                        last.type = 'mesos'
                        return last;
                    }

                    // Used Package?
                    if( taggedOffers.find( o => o.type === 'pack') ){
                        last.type = 'pack'
                        return last;
                    }

                    // Used Package?
                    if( taggedOffers.find( o => o.type === 'ibi') ){
                        last.type = 'ibi'
                        return last;
                    }

                    // // Used MESO?
                    // const mesos = this.getMesos( p.items )
                    // if( mesos.length > 0 ){
                    //     last.type = 'mesos'
                    //     last.mesos = mesos
                    // }else{
                    //     last.type = 'nomesos'
                    // }
                
            });

        }

        const lastOfferPerGroupMesos = lastOfferPerGroup.filter( o => o && o.type === 'mesos' );
        const lastOfferPerGroupPack  = lastOfferPerGroup.filter( o => o && o.type === 'pack' );
        const lastOfferPerGroupIbi   = lastOfferPerGroup.filter( o => o && o.type === 'ibi' );
        
        const plotPointsMesos = lastOfferPerGroupMesos.map( o => this.totalPoints(o) );
        const plotPointsPack  = lastOfferPerGroupPack.map( o => this.totalPoints(o) );
        const plotPointsIbi   = lastOfferPerGroupIbi.map( o => this.totalPoints(o) );

        // Prepare the groups to be listed in the pair details modal
        let modalGroups = []
        if( this.state.clicked )
            modalGroups = game.data.groups && game.data.groups.filter( g => {
                const accepted = g.items.find( o => o.status === 'accepted' )
                // console.log({ accepted })
                // console.log(this.totalPoints( accepted ), this.state.clicked)
                return accepted && JSON.stringify( this.totalPoints( accepted ) ) === JSON.stringify(this.state.clicked)
            })

        console.log({ modalGroups })

        return <div>

            <Header />

            <h2 className="mt-3 sec-title">
                Pareto
                <small className="float-right">
                    <DebriefMenu game_id={game.data._id} location={this.props.location} />
                </small>
            </h2>

            <div className="row">
                <div className="col-6 offset-3">
                    
                    <h5 className="text-center mt-3">
                        Final Offer: Candidate vs Recruiter (pts)
                    </h5>

                    <div className="mt-3">
                    <HighchartsReact
                            highcharts={ Highcharts }
                            options={{ 
                                title: { text: '' },
                                chart: { type: 'scatter', height: '70%' },
                                credits: { enabled: false },
                                legend: { enabled: true },
                                xAxis: {
                                    title: { text: 'Candidate (pts)' },
                                    plotBands: [{ color: 'rgba(0,0,0,.1)', width: 2, value: 0 }]
                                },
                                yAxis: {
                                    title: { text: 'Recruiter (pts)' },
                                    allowDecimals: false,
                                    plotLines: [{ color: 'rgba(0,0,0,.1)', width: 2, value: 0 }]
                                },
                                plotOptions: {
                                    scatter: {
                                        marker: {
                                            radius: 15,
                                            // fillColor: 'rgba(0,0,0,.3)',
                                            states: {
                                                hover: {
                                                    enabled: true,
                                                    lineColor: 'rgba(0,0,0,.4)',
                                                }
                                            }
                                        },
                                        states: {
                                            hover: {
                                                marker: {
                                                    enabled: false
                                                }
                                            }
                                        },
                                        tooltip: {
                                            headerFormat: '<b>{series.name}</b><br>',
                                            pointFormat: '<b>Candidate</b>: {point.x:,.0f} pts<br/><b>Recruiter</b>: {point.y:,.0f} pts'
                                        }
                                    },
                                    spline: {
                                        tooltip: {
                                            headerFormat: '<b>{series.name}</b><br>',
                                            pointFormat: '<b>Candidate</b>: {point.x:,.0f} pts<br/><b>Recruiter</b>: {point.y:,.0f} pts'
                                        }
                                    },
                                    series: {
                                        cursor: 'pointer',
                                        point: {
                                            events: {
                                                click: (e) => {
                                                    if( e.point.series.name === 'Used Issue-by-Issue' || e.point.series.name === 'Used Package' || e.point.series.name === 'Used MESOs' ){
                                                        self.groupsModal(e)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                series: [{
                                    name: 'Used MESOs',
                                    data: plotPointsMesos,
                                    zIndex: 6
                                }, {
                                    name: 'Used Package',
                                    data: plotPointsPack,
                                    zIndex: 5
                                }, {
                                    name: 'Used Issue-by-Issue',
                                    data: plotPointsIbi,
                                    zIndex: 4
                                }, {
                                    type: 'spline',
                                    name: 'Pareto',
                                    color: '#CCC',
                                    data: [
                                        [-4800, 13200],[-1600, 12400],[2400, 10800],[5400, 7800],[6600, 6600],[7800, 5400],[10800, 2400],[12400, -1600],[13200, -4800]
                                    ],
                                    zIndex: 3
                                }, {
                                    type: 'spline',
                                    name: 'Distributive Optimal',
                                    color: 'red',
                                    marker: { radius: 7 },
                                    data: [ [2400, 2400] ],
                                    zIndex: 2
                                }, {
                                    type: 'spline',
                                    name: 'Compatible Optimal',
                                    color: 'orange',
                                    marker: { radius: 7 },
                                    data: [ [4200, 4200] ],
                                    zIndex: 1
                                }]
                            }}
                            />
                    </div>

                </div>
            </div>

            { this.state.clicked && <GroupBtns 
                onClose={ () => this.setState({ clicked: null }) }
                modalGroups={ modalGroups } /> }
            
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

const connectedPage = connect(mapStateToProps)(Pareto);
export { connectedPage as GamePareto };
