import { userConstants } from '../constants';
import { history } from '../helpers'

export const authActions = {
    authenticate
};


function authenticate( qs ) {
    return dispatch => {
        fetch( process.env.REACT_APP_API_URL + '/auth/oauth_callback' + qs )
        .then( res => res.json() )
        .then( user => {
            localStorage.setItem('scjwt', JSON.stringify(user))
            dispatch({ type: userConstants.LOGIN_SUCCESS, user })
            if( ['administrator', 'instructor'].indexOf(user.role) > -1 )
                history.push('/games')
            else
                history.push('../play')
        })
    };
}