import React from 'react'
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import Evolution from './Evolution';
import Summary from './Summary';
import ChatHistory from './ChatHistory';

// The Header creates links that can be used to navigate
// between routes.
class StudentDebrief extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: 1
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e){
        const n  = e.target.name;
        const v  = e.target.value;
        let data = {}
        data[n]  = v;
        this.setState( data );
    }

    render(){

        const { group } = this.props;
        const maxOffers = group.items.length;
        const offer     = group.items[this.state.order-1]

        const player1 = group.players[0];
        const player2 = group.players[1];

        return <div>
            <div className="row">
                <div className="col-10 offset-1 mb-4">
                    <h5 className="mt-2 mb-0 float-right">
                        Pair: {player1.email} &amp; {player2.email}
                    </h5>
                    { !offer && <h5 className="mb-0 mt-2 text-left float-left text-danger">No offers were exchanged</h5> }
                    { offer && <h5 className="mb-0 mt-2 float-left">Offer #{this.state.order} of {group.items.length}</h5> }
                    { offer && <input name="order" type="range" min="1" max={maxOffers} value={ this.state.order } onChange={ this.handleChange } /> }
                </div>
                { offer && <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Offer Evolution Over Time
                        </div>
                        <div className="card-body" style={{ height: '67vh', overflow: 'auto' }}>
                            { offer && <Evolution offer={ offer } /> }
                        </div>
                    </div>
                </div> }
                { offer && <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Offer Details
                        </div>
                        <div className="card-body" style={{ height: '67vh', overflow: 'auto' }}>
                            { offer && <Summary offer={ offer } /> }
                        </div>
                    </div>
                </div> }
                <div className="col">
                    <div className="card">
                        <div className="card-header">
                            Chat History
                        </div>
                        <div className="card-body" style={{ height: '67vh', overflow: 'auto' }}>
                            <ChatHistory group={ group } offer={ offer } />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

export default connect(mapStateToProps)(StudentDebrief);