//import { alertConstants } from '../constants';

export function modal(state = {}, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { visibility: true };
    case "CLOSE_MODAL":
      return { visibility: false };
    default:
      return state
  }
}