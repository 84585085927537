export const issues = [
    { 
        button: 'Salary',
        label: 'Salary', 
        key: 'salary',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: '$100,000 ', points: [-6000,0] }, 
            { label: '$105,000 ', points: [-4500,-1500] },
            { label: '$110,000 ', points: [-3000,-3000] },
            { label: '$115,000 ', points: [-1500,-4500] },
            { label: '$120,000 ', points: [0,-6000] }
        ]
    },
    { 
        button: 'Loc.',
        label: 'Location', 
        key: 'location',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: 'San Francisco', points: [1200,1200] }, 
            { label: 'Atlanta', points: [900,900] }, 
            { label: 'Chicago', points: [600,600] }, 
            { label: 'Boston', points: [300,300] }, 
            { label: 'New York', points: [0,0] }
        ]
    },
    { 
        button: 'Bonus',
        label: 'Bonus', 
        key: 'bonus',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: '2%', points: [0,1600] }, 
            { label: '4%', points: [1000,1200] }, 
            { label: '6%', points: [2000,800] }, 
            { label: '8%', points: [3000,400] }, 
            { label: '10%', points: [4000,0] }
        ]
    },
    { 
        button: 'Vac.',
        label: 'Vacation', 
        key: 'vacation',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: '10 days', points: [0,4000] }, 
            { label: '12 days', points: [400,3000] }, 
            { label: '14 days', points: [800,2000] }, 
            { label: '16 days', points: [1200,1000] }, 
            { label: '18 days',  points: [1600,0] }
        ]
    },
    { 
        button: 'Mov.',
        label: 'Moving Expenses', 
        key: 'moving',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: '100%', points: [3200,0] }, 
            { label: '90%',  points: [2400,200] }, 
            { label: '80%',  points: [1600,400] }, 
            { label: '70%',  points: [800,600] }, 
            { label: '60%',  points: [0,800] }
        ]
    },
    { 
        button: 'Ins.',
        label: 'Insurance', 
        key: 'insurance',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: 'Plan A', points: [800,0] }, 
            { label: 'Plan B', points: [600,800] }, 
            { label: 'Plan C', points: [400,1600] }, 
            { label: 'Plan D', points: [200,2400] }, 
            { label: 'Plan E', points: [0,3200] }
        ]
    },
    { 
        button: 'Job',
        label: 'Job Assignment', 
        key: 'job',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: 'Division A', points: [0,0] }, 
            { label: 'Division B', points: [-600,-600] }, 
            { label: 'Division C', points: [-1200,-1200] }, 
            { label: 'Division D', points: [-1800,-1800] }, 
            { label: 'Division E', points: [-2400,-2400] }
        ]
    },{ 
        button: 'Start',
        label: 'Starting Date', 
        key: 'starting',
        values: [
            { label: '--', points: [-99999,-99999], default: true }, 
            { label: 'June 1',   points: [2400,0] }, 
            { label: 'June 15',  points: [1800,600] }, 
            { label: 'July 1',   points: [1200,1200] }, 
            { label: 'July 15',  points: [600,1800] }, 
            { label: 'August 1', points: [0,2400] }
        ]
    }
]