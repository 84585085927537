import React from 'react'
import { connect } from 'react-redux';

import numeral from 'numeral';


// The Header creates links that can be used to navigate
// between routes.
class Summary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: 1
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange(e){
        const n  = e.target.name;
        const v  = e.target.value;
        let data = {}
        data[n]  = v;
        this.setState( data );
    }

    totalPoints( o ){
        let total = [0, 0];
        if( o.data ){
            for( let i = 0; i < o.data.length; i++ ){
                const issue = o.data[i];
                const selected = issue.values.find( v => v.selected && !v.default )
                if( selected ){
                    total[0] += selected.points[0]
                    total[1] += selected.points[1]
                }
                if( i === o.data.length-1 )
                    return total;
            }
        }
    }

    render(){

        const { offer } = this.props;
        const totals = this.totalPoints(offer)
        const issues = offer.data.map( (issue, i) => {
            const value = issue.values.find( v => v.selected );
            return <div key={'iss-' + i} className="col-6 text-center mt-4">
                <h6><b>{ value ? value.label : '--' }</b></h6>
                { issue.label }
            </div>
        })

        return <div>
            <div className="row mt-3">
                <div className="col-4 text-center">
                    <h6><b>{ numeral(totals[0] + totals[1]).format('0,0') }</b> pts.</h6>
                    Total
                </div>
                <div className="col-4 text-center">
                    <h6><b>{ numeral(totals[0]).format('0,0') }</b> pts.</h6>
                    Candidate
                </div>
                <div className="col-4 text-center">
                    <h6><b>{ numeral(totals[1]).format('0,0') }</b> pts.</h6>
                    Recruiter
                </div>
            </div>
            <div className="row mt-3">
                { issues }
            </div>
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

export default connect(mapStateToProps)(Summary);