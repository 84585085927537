import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import numeral from 'numeral';
import moment from 'moment';

// Actions
// import { gameActions } from '../../actions';

// Helpers
// import { setupSocket, socketEmit, socketOn } from '../../helpers/sockets';

// import { Modal, ModalBody } from 'reactstrap';
import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';
import List from './list';
// import GroupDebrief from '../../components/GroupDebrief';

class Leaderboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: null,
      scores: [],
    };

    // Bindings
    this.rowClick = this.rowClick.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      // this.props.dispatch(gameActions.fetchGames(this.props.match.params.id))

      fetch(`/api/games/${this.props.match.params.id}/scores`)
        .then((res) => res.json())
        .then((data) => {
          this.setState({ scores: data });
        });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  totalPoints(o) {
    let total = [0, 0];
    if (o.data) {
      for (let i = 0; i < o.data.length; i++) {
        const issue = o.data[i];
        const selected = issue.values.find((v) => v.selected);
        if (selected) {
          total[0] += selected.points[0];
          total[1] += selected.points[1];
        }
        if (i === o.data.length - 1) return total;
      }
    }
  }

  getMesos(offers) {
    return offers.filter((it, i) => {
      const prev = i > 0 ? offers[i - 1] : null;
      return prev && prev.order === it.order;
    });
  }

  extractValues(offer) {
    if (offer)
      return offer.data.map((issue) => issue.values.find((v) => v.selected));
    else return [];
  }

  rowClick(data, index) {
    this.setState({ group: data });
  }

  render() {
    const { game } = this.props;
    const { scores } = this.state;

    const scoresList = scores.map((s) => ({
      name: s.nickname,
      email: s.email,
      recycled: {
        value: s.recycled * 24 * 5 * 4 * 3,
        label: numeral(s.recycled * 24 * 5 * 4 * 3).format('0,0'),
      },
      capacity: {
        value: s.results[3] ? s.results[3].min_capacity : 0,
        label: numeral(s.results[3] ? s.results[3].min_capacity : 0).format(
          '0,0'
        ),
      },
      performance: {
        value: s.performance,
        label: numeral(s.performance).format('0,0') + '%',
      },
      flag:
        s.results[3] &&
        s.results[3].used > 0 &&
        !s.results[3].positive_demand ? (
          <small className='badge badge-danger'>Sanction</small>
        ) : (
          'No'
        ),
      date: moment(s.created_at).format('LLL'),
      duration: {
        value: s.duration ? s.duration : 0,
        label: s.duration ? moment.utc(s.duration).format('HH:mm:ss') : 0,
      },
    }));

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Leaderboard
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div style={{ fontSize: '14px' }}>
          <List
            labels={[
              'Name',
              'Email',
              {
                label: 'Total Recycled (lbs)',
                tooltip:
                  'This metric varies depending on the demand and throughput levels set at the beginning of each gameplay.',
              },
              {
                label: 'Final Capacity (lbs/hr)',
                tooltip:
                  'This metric varies depending on the throughput levels set at the beginning of each gameplay.',
              },
              {
                label: 'Correct Machine Deployment',
                tooltip:
                  'Measures if students made the correct process optimization decision each time an asset was available. If a bottleneck existed, the available asset was deployed to the correct station. If the system was demand-constrained, an available asset was not deployed. Max:100%.',
              },
              {
                label: 'Rd. 4 Sanction',
                tooltip:
                  'Players receive a sanction from management for deploying machines in round 4 since the system is demand-constrained and there is no bottleneck. In round 4, players receive a sanction if they deploy resources when the process is demand constrained and there is no bottleneck.',
              },
              'Date',
              {
                label: 'Duration',
                tooltip:
                  'Time elapsed from initial decision to final submission.',
              },
            ]}
            fields={[
              'name',
              'email',
              'recycled',
              'capacity',
              'performance',
              'flag',
              'date',
              'duration',
            ]}
            sortables={[
              'name',
              'email',
              'recycled',
              'capacity',
              'performance',
              'flag',
              'date',
              'duration',
            ]}
            data={scoresList}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

const connectedLeaderboardPage = connect(mapStateToProps)(Leaderboard);
export { connectedLeaderboardPage as GameLeaderboard };
