import React from 'react'
import { connect } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

// The Header creates links that can be used to navigate
// between routes.
class Evolution extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    totalPoints( o ){
        let total = [0, 0];
        if( o.data ){
            for( let i = 0; i < o.data.length; i++ ){
                const issue = o.data[i];
                const selected = issue.values.find( v => v.selected )
                if( selected ){
                    total[0] += selected.points[0]
                    total[1] += selected.points[1]
                }
                if( i === o.data.length-1 )
                    return total;
            }
        }
    }

    render(){

        const { offer } = this.props;

        const categories = offer ? offer.data.map( issue => issue.label ) : []
        const offerData = offer ? offer.data.map( issue => issue.values.find( v => v.selected && !v.default ) ) : []
        const candidatePoints = offerData.map( issue => issue ? issue.points[0] : null )
        const recruiterPoints = offerData.map( issue => issue ? issue.points[1] : null )

        return <div>

            <HighchartsReact
                highcharts={ Highcharts }
                options={{ 
                    title: { text: '' },
                    chart: { type: 'column' },
                    credits: { enabled: false },
                    legend: { enabled: true },
                    xAxis: {
                        title: { text: 'Candidate (pts)' },
                        categories: categories
                    },
                    yAxis: {
                        title: { text: 'Recruiter (pts)' },
                        allowDecimals: false,
                        min: -8000,
                        max: 8000,
                        // tickInterval: 1000,
                        plotLines: [{ color: 'rgba(0,0,0,.5)', width: 2, value: 0 }]
                    },
                    series: [{
                        name: 'Candidate',
                        color: '#16c1b7',
                        data: candidatePoints
                    }, {
                        name: 'Recruiter',
                        color: '#612ea0',
                        data: recruiterPoints
                    }]
                }}
                />
        </div>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

export default connect(mapStateToProps)(Evolution);