import React from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: this.props.labels || [],
      fields: this.props.fields || [],
      sortables: this.props.sortables || [],
      data: this.props.data || [],
      sort: -1,
      sortedBy: null,
      tips: {},
    };

    // Bindings
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      data: newProps.data,
    });
  }

  sortTable(col) {
    const sort = this.state.sort;
    const field = this.state.fields[col];
    const sortFunction = (a, b) => {
      const aVal = a[field].value ? a[field].value : a[field];
      const bVal = b[field].value ? b[field].value : b[field];
      if (aVal < bVal) return -1 * sort;
      if (aVal > bVal) return 1 * sort;
      return 0;
    };
    let toBeSortedItems = [...this.state.data];
    let numericItems = toBeSortedItems.filter((i) =>
      i[field].value ? !isNaN(i[field].value) : !isNaN(i[field])
    );
    let alphaItems = toBeSortedItems.filter((i) =>
      i[field].value ? isNaN(i[field].value) : isNaN(i[field])
    );
    numericItems.sort(sortFunction);
    alphaItems.sort(sortFunction);
    const sortedItems = numericItems.concat(alphaItems);
    this.setState({
      data: sortedItems,
      sort: this.state.sort * -1,
      sortedBy: col,
    });
  }

  onRowClick(data, index) {
    if (this.props.onRowClick) this.props.onRowClick(data, index);
  }

  toggleTooltip(tix, value = null) {
    const tips = { ...this.state.tips };
    tips[tix] = value !== null ? value : !tips[tix];
    this.setState({ tips });
  }

  render() {
    const { tips } = this.state;

    const size = this.props.size || '';

    const keys = this.state.fields;
    const headings = this.state.labels.map((k, ki) => {
      const active = this.state.sortedBy === ki ? 'active' : '';
      const arrow = this.state.sort === -1 ? '↑' : '↓';
      const sortable =
        this.state.sortables.indexOf(keys[ki]) > -1 ? true : false;

      return sortable ? (
        <th
          key={'th-' + ki}
          onClick={() => this.sortTable(ki)}
          className={'text-center ' + active}>
          {k.label || k}{' '}
          {active && (
            <small className='badge badge-pill badge-primary'>{arrow}</small>
          )}
          {k.tooltip && k.tooltip.length > 0 && (
            <b
              id={`tooltip-${ki}`}
              className='badge badge-warning ml-1'
              onMouseEnter={(e) => this.toggleTooltip(ki, true)}
              onMouseLeave={(e) => this.toggleTooltip(ki, false)}>
              ?
            </b>
          )}
          {k.tooltip && k.tooltip.length > 0 && (
            <Tooltip
              placement='top'
              isOpen={tips[ki] ? true : false}
              target={`tooltip-${ki}`}>
              {k.tooltip}
            </Tooltip>
          )}
        </th>
      ) : (
        <th key={'th-' + ki} className='text-center'>
          {k}
        </th>
      );
    });
    const rows = this.state.data.map((d, di) => {
      let cols = keys.map((k, ki) => {
        const value = typeof d[k].value !== 'undefined' ? d[k].value : d[k];
        const label = typeof d[k].label !== 'undefined' ? d[k].label : value;
        return (
          <td
            onClick={() => this.onRowClick(d, di)}
            key={'td-' + ki}
            className='text-center'>
            {label}
          </td>
        );
      });
      return <tr key={'tr-' + di}>{cols}</tr>;
    });

    return (
      <table className={`table table-hover ${size}`}>
        <thead className=''>
          <tr>{headings}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(List);
