import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import Header from '../../components/Header';

// Actions
import { gameActions } from '../../actions';

class New extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            instructors: [],
            name: '',
            instructor: '',
            type: 'link'
        }

        // Bindings
        this.handleChange   = this.handleChange.bind(this)
        this.setType        = this.setType.bind(this)
        this.submit         = this.submit.bind(this)
        this.hubGetUsers    = this.hubGetUsers.bind(this)

    }

    componentDidMount(){
        const { user } = this.props
        console.log({ user })
        if( user.role === 'administrator' )
            this.hubGetUsers()
        else
            this.setState({ instructors: [user], instructor: user._id })
    }

    componentWillReceiveProps(newProps){ 
        this.setState({
            data: newProps.data
        });
    }

    handleChange(e){
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change)
    }

    setType(type){
        this.setState({ type })
    }

    hubGetUsers(){
        fetch( process.env.REACT_APP_API_URL + '/hub/users?role[]=instructor&role[]=administrator', {
            method: 'GET'
        })
        .then( response => response.json() )
        .then( data => {
            this.setState({ instructors: data })
        })
    }

    submit(){
        const data = {
            name: this.state.name,
            instructor: this.state.instructor,
            access_type: this.state.type
        }
        this.props.dispatch(gameActions.createGame(data))
    }

    render(){

        const { user, game } = this.props;
        const { name, instructor, type, instructors } = this.state;

        // If there's game data, then redirect to it
        if( game.data && game.data._id )
            return <Redirect to={`/games/${game.data._id}`} />

        const instructorOpts = instructors.map( (i, ii) => {
            const name = i.name.length > 0 && i.lastname.length > 0 ? `${i.name} ${i.lastname}` : i.email;
            return <option 
                key={ `ins-${ii}` } 
                onClick={ this.handleChange }
                value={ i.id ? i.id : i._id }>{ name }</option>
        })
        
        const canSubmit = name.length > 0 && type.length > 0 && instructor.length > 0 ? true : false;

        return <div className="">

            <Header />

            <h2 className="mt-3 sec-title">New Game</h2>

            <div className="row mt-4">
                <div className="col-8 offset-2">
                    <div className="row">
                        <div className="col-6">
                            <div className="input-group input-group-lg">
                                <input name="name" className="form-control" onChange={ this.handleChange } placeholder="Enter Game Name" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group input-group-lg">
                            <select name="instructor" className="form-control" value={instructor} onChange={ this.handleChange } disabled={user.role === 'instructor'} >
                                <option value="">Select Instructor</option>
                                {instructorOpts}
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">

                        <div className="col-12 text-center lead mb-3">
                            Review how you add students below:
                        </div>

                        {/* <div className="col-4">
                            <div className={'card card-selectable ' + (type === 'lti' ? 'selected' : '')} onClick={ () => this.setType('lti') }>
                                <div className="card-body">

                                    <h5>LMS/LTI</h5>

                                    <b>Instructor/Admin:</b>
                                    <ul>
                                        <li>Verify LMS setup</li>
                                    </ul>

                                    <b>Student Process:</b>
                                    <ul>
                                        <li>Login to LMS</li>
                                        <li>Select Course & Module</li>
                                        <li>Click on Link</li>
                                    </ul>

                                </div>
                            </div>
                        </div> */}

                        <div className="col-6">
                            <div className={'card card-selectable ' + (type === 'link' ? 'selected' : '')} onClick={ () => this.setType('link') }>
                                <div className="card-body">

                                    <h5>EMAIL + LINK</h5>

                                    <b>Instructor/Admin:</b>
                                    <ul>
                                        <li>Display registration link</li>
                                    </ul>

                                    <b>Student Process:</b>
                                    <ul>
                                        <li>Register Via Link</li>
                                        <li>Wait for Game Launch</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        
                        <div className="col-6">
                            <div className={'card card-selectable ' + (type === 'csv' ? 'selected' : '')} onClick={ () => this.setType('csv') }>
                                <div className="card-body">

                                    <h5>CSV</h5>

                                    <b>Instructor/Admin:</b>
                                    <ul>
                                        <li>Upload participants</li>
                                    </ul>

                                    <b>Student Process:</b>
                                    <ul>
                                        <li>Check email</li>
                                        <li>Click on Link</li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div className="text-center col-12 mt-5">
                            <button className="btn btn-outline-primary btn-lg mr-2" disabled={!canSubmit} onClick={ this.submit }>
                                Create
                            </button>
                            <Link to="/games" className="btn btn-light btn-lg ml-2">
                                Cancel
                            </Link>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    }

}

function mapStateToProps(state) {
    const { authentication, player, game } = state;
    return {
        user: authentication.user,
        game,
        player
    };
}

const connectedGamesNew = connect(mapStateToProps)(New);
export { connectedGamesNew as GameNew };
