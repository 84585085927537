import React from 'react'
import { connect } from 'react-redux';

import { Modal, ModalBody } from 'reactstrap';
import GroupDebrief from './GroupDebrief';

// The Header creates links that can be used to navigate
// between routes.
class GroupBtns extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
            group: null
        }

        // Bindings
        this.setRevGroup = this.setRevGroup.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    setRevGroup( group ){
        this.setState({ group })
    }

    toggleModal(){
        this.setState({ open: !this.state.open }, () => {
            if(!this.state.open && this.props.onClose)
                this.props.onClose()
        })
    }

    render(){

        const { modalGroups } = this.props;
        const size = this.state.group ? 'xxl' : '';

        return <div>
                <Modal isOpen={ this.state.open } 
                    centered={true} 
                    size={size} 
                    toggle={this.toggleModal}>
                    <ModalBody className="text-center">

                        { !this.state.group && <div>
                            <p className="lead">Click on the pair you want to review</p>
                            { modalGroups.map( (g,gi) => <div key={'gr-' + gi} onClick={ () => this.setRevGroup(g) } className="btn btn-md btn-outline-primary mb-2">
                                { g.players.map( (p,pi) => {
                                    if( pi > 0 )
                                        return [
                                            <span key={'amp-' + pi} className="d-inline-block ml-2 mr-2">&</span>, 
                                            <span key={'pl-' + pi}>{ p.name.length > 0 ? p.name : p.email }</span>
                                        ]
                                    else
                                        return <span key={'pl-' + pi}>{ p.name.length > 0 ? p.name : p.email }</span>
                                }) }
                            </div> ) } 
                        </div> }

                        { this.state.group && <GroupDebrief group={ this.state.group } /> }

                    </ModalBody>
                </Modal>
        </div>
    }

}

function mapStateToProps(state) {
    const {} = state;
    return {};
}

export default connect(mapStateToProps)(GroupBtns);